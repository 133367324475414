import React from 'react'
import Portal from './Portal'
import Text from './Text'

const StickyFlyer = ({ className = '', onFlyerClick, title }) => {
    return (
        <div className={`${className} fixed flex w-[95%] max-w-[610px] left-[calc(100%_-_25px)] md:left-[calc(100%_-_28px)] top-1/2 -translate-y-1/2 min-h-[148px] md:min-h-[200px] z-100`}>
            <div onClick={onFlyerClick} data-automation='sticky-slider-open-close-button' className='rounded-l-sm-0.5 bg-other-purple-600 w-7 relative text-basic-white leading-2.5 select-none cursor-pointer shrink-0'>
                <Text className='text-sm font-semibold absolute -rotate-90 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap'>{title}</Text>
            </div>
        </div>
    )
}

export default Portal(StickyFlyer)