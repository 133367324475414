import React, { useEffect, useState } from 'react';
import Button from "atoms/Button";
import MakeAppointment from './MakeAppointment';
import Card from 'atoms/Card';
import CardBody from 'atoms/CardBody';
import Text from 'atoms/Text';
import Heading from 'atoms/Heading';
import StickyFlyer from 'atoms/StickyFlyer';
import { useRouter } from 'next/router';
import appointment_flow from "../../assets/mo_engage/appointment_flow.json";
import { APPOINTMENT_GA_CATEGORY } from 'constant/myAppointment';
import { captureEvent } from 'services/analytics.service';

const ConsultCard = ({ doctorId, stickyFlyer }) => {
  const [showBookAppointmentModal, setBookAppointmentModal] = useState(false);
  const router = useRouter();
  const showBookAppointmentFormModal = async () => {
    const eventCategory = APPOINTMENT_GA_CATEGORY.TREATMENT;
    const eventName = appointment_flow?.entity?.Request_Appointment_Click?.event_name;
    captureEvent(eventCategory, eventName, '', {action:'Click'});
    router.push('/ayurveda/doctors');
  }
  return (
    <>
      <StickyFlyer className={`${stickyFlyer ? '' : 'md:hidden'} `} onFlyerClick={showBookAppointmentFormModal} title='Consult a Doctor' />
      {!stickyFlyer ?
        <Card type='regularTwo' className='bg-transparent-0'>
          <CardBody className='bg-basic-white flex flex-col overflow-hidden min-h-auto p-4 justify-center gap-2 rounded-lg'>
            <div className='regularCard consultCard' >
              <Heading className="font-Montserrat text-18-28 md:text-24-34 text-gray-900 mb-2" type='H2' weight={600}>Consult a Doctor</Heading>
              <Text type='caption' className={`text-gray-700 text-sm`}>Unlock the power of Ayurveda with expert advice from our experienced practitioners. Request an appointment today and take the first step towards a healthier, happier you.</Text>
              <div className='flex justify-center'>
                <Button className="makeAppointmentSubmitButton min-h-[40px] py-0 mt-4"
                  onClick={showBookAppointmentFormModal}
                  type={"submit"}
                >Book Video Consultation</Button>
              </div>
            </div >
          </CardBody>
        </Card>
        : null
      }
      {showBookAppointmentModal &&
        <MakeAppointment
          isModalOpen={showBookAppointmentModal}
          setIsModalOpen={setBookAppointmentModal}
          section={'doctorpage'}
          buttonId={'makeAppointment'}
          isCoverContent={false}
          isFrontForm={false}
          doctorId={doctorId}
          isPopup={true}
        />
      }
    </>
  )
}
export default ConsultCard